<template>
  <section class="content">
    <div class="card">
      <div class="card-body">
        <table class="table table-hover" ref="tblbukutamu">
          <thead>
            <tr>
              <th>TANGGAL</th>
              <th>WAKTU</th>
              <th>NAMA</th>
              <th>PERUSAHAAN</th>
              <th>NO. HP/WA</th>
              <th>BERTEMU</th>
              <th>KEPERLUAN</th>
            </tr>
          </thead>
          <tbody @click="handleClick"></tbody>
        </table>
      </div>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </p>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">TANGGAL</label>
                  <datepicker v-model="form.tanggal" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">WAKTU</label>
                  <div class="row">
                    <timepicker
                      class="col-md-5 ml-2"
                      placeholder="Jam mulai"
                      v-model="form.jam_mulai"
                    />
                    <timepicker
                      class="col-md-5"
                      placeholder="Jam selesai"
                      v-model="form.jam_selesai"
                    />
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">NAMA</label>
                  <input
                    id="nama_tamu"
                    class="form-control"
                    v-model="form.nama_tamu"
                    type="text"
                    name="nama_tamu"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">PERUSAHAAN</label>
                  <input
                    id="perusahaan_tamu"
                    class="form-control"
                    v-model="form.perusahaan_tamu"
                    type="text"
                    name="perusahaan_tamu"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">NO. HP/WA</label>
                  <input
                    id="no_hp"
                    class="form-control"
                    v-model="form.no_hp"
                    type="number"
                    name="no_hp"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">TUJUAN</label>
                  <input
                    id="tujuan"
                    class="form-control"
                    v-model="form.tujuan"
                    type="text"
                    name="tujuan"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">KEPERLUAN</label>
                  <textarea
                    class="form-control"
                    id="keperluan"
                    rows="3"
                    v-model="form.keperluan"
                    placeholder="Tuliskan keperluan tamu"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import datepicker from "@/components/Datepicker";
import timepicker from "@/components/Timepicker";
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah Buku Tamu",
      form: {
        nama_tamu: "",
        tanggal: "",
        jam_mulai: "",
        keperluan: "",
        tujuan: "",
        jam_selesai: "",
        perusahaan_tamu: "",
        no_hp: "",
      },
      downloading: false,
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    downloadXlsx: function (e) {
      this.downloading = true;
      authFetch("/administrasi/buku_tamu/all_bukutamu", {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          this.loadingDownload = false;
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "tes.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      this.table.api().ajax.reload();
    },
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/administrasi/buku_tamu";
      if (this.method == "PUT")
        urlSubmit = "/administrasi/buku_tamu/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  components: {
    datepicker,
    timepicker,
    datePicker,
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblbukutamu, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/administrasi/buku_tamu",
      columns: [
        { data: "tanggal" },
        { data: "jam_mulai" },
        { data: "nama_tamu" },
        { data: "perusahaan_tamu" },
        { data: "no_hp" },
        { data: "tujuan" },
        { data: "keperluan" },
      ],
      filterBy: [0, 1, 2, 3],
      rowCallback: function (row, data) {},
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {
            nama_tamu: "",
            tanggal: "",
            jam_mulai: "",
            keperluan: "",
            tujuan: "",
            jam_selesai: "",
            perusahaan_tamu: "",
            no_hp: "",
          };
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Buku Tamu";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Buku Tamu";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/administrasi/buku_tamu/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
      dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
          {
              extend: 'excel',
              text: 'XLSX',
              className: 'btn btn-success',
              exportOptions: {
                  columns:[0,1,2,3,4,5,6],
              },
              title: function(){
                  return 'Buku Tamu';
              },
              filename: function(){
                  return 'Buku Tamu';
              },
          },
          {
              extend: 'print',
              text: 'Print',
              className: 'btn btn-primary',
              exportOptions: {
                  columns:[0,1,2,3,4,5,6],
              },
              title: function(){
                  return 'Buku Tamu';
              },
              filename: function(){
                  return 'Buku Tamu';
              },
          },
          {
              extend: 'pdf',
              text: 'PDF',
              className: 'btn btn-info',
              exportOptions: {
                  columns:[0,1,2,3,4,5,6],
              },
              title: function(){
                  return 'Buku Tamu';
              },
              filename: function(){
                  return 'Buku Tamu';
              },
          },
          {
              extend: 'csvHtml5',
              text: 'CSV',
              className: 'btn bg-gray-dark',
              exportOptions: {
                  columns:[0,1,2,3,4,5,6],
              },
              title: function(){
                  return 'Buku Tamu';
              },
              filename: function(){
                  return 'Buku Tamu';
              },
          },
      ],
    });
  },
};
</script>
